import React, { Suspense, useEffect, useRef } from "react";
import { Canvas,useFrame } from "react-three-fiber";
import { useGLTF, OrbitControls,useAnimations } from "@react-three/drei";
function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/almaz.glb')
  const { actions } = useAnimations(animations, group)
  useFrame(()=>group.current.rotation.x = Math.PI)
  useEffect(()=>{
    actions.Animation.play()
  })
  return (
    <group ref={group} {...props} dispose={null} scale={15}
    position={[-0.03, 2, -0.01]} >
      <group name="Scene">
        <mesh name="Vert008" geometry={nodes.Vert008?.geometry} material={materials?.black} position={[0.02, -0.2, -0.01]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.19}>
          <mesh name="Vert006_1" geometry={nodes.Vert006_1?.geometry} material={materials['black.001']} position={[0, 0.18, 0]} rotation={[Math.PI, 0, Math.PI]} scale={-1} />
          <mesh name="Vert007_1" geometry={nodes.Vert007_1?.geometry} material={materials['black.001']} position={[0, 0.09, 0]} rotation={[Math.PI, 0, Math.PI]} scale={-1} />
        </mesh>
        <mesh name="Vert005" geometry={nodes.Vert005?.geometry} material={materials?.mis} position={[0.02, -0.2, -0.01]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={-0.19} />
        <mesh name="03model_003" geometry={nodes['03model_003']?.geometry} material={materials['black.001']} position={[0.02, -0.2, 0]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.19} />
        <mesh name="01_model012" geometry={nodes['01_model012']?.geometry} material={materials?.nikel} position={[0.02, -0.2, -0.01]} rotation={[Math.PI / 2, 1.57, 0]} scale={-0.19} />
        <mesh name="01_model010" geometry={nodes['01_model010']?.geometry} material={materials?.mis} position={[0.02, 0.29, 0]} rotation={[Math.PI / 2, 1.57, 0]} />
        <mesh name="01_model009" geometry={nodes['01_model009']?.geometry} material={materials?.nikel} position={[0.02, -0.2, 0]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.19} />
        <mesh name="Vert001" geometry={nodes.Vert001?.geometry} material={materials?.mis} position={[0.02, 0.16, -0.01]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[-0.12, -0.06, -0.12]} />
        <mesh name="Vert002" geometry={nodes.Vert002?.geometry} material={materials?.mis} position={[0.02, 0.16, -0.01]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[-0.12, -0.06, -0.12]} />
        <mesh name="Plane" geometry={nodes.Plane?.geometry} material={materials['nikel.lezva']} position={[0.25, 1.01, 0.43]} rotation={[3.03, 1.46, 1.69]} scale={[-1, -1, -1.06]} />
      </group>
    </group>
  )
}

useGLTF.preload('/almaz.glb')




const ModelThree = () => {
  try {
    return ( 
      <div className="threejs">
        <Canvas camera={{ fov: 80, position: [0, 0, 15], zoom: 1.3,animations:true ,  }} >
          <Suspense fallback={null}>
            <pointLight intensity={50} position={[0, -100, 0]} />
            <spotLight
              position={[50, 0, 0]}
              intensity={10.5}
              angle={10.1}
              penumbra={1}
              castShadow
            />
            <hemisphereLight intensity={0.5} />
            <directionalLight intensity={2} position={[0, 0, 5]} />
            <directionalLight intensity={2} position={[0, -0, -5]} />
            <directionalLight intensity={2} position={[0, -5, -0]} />
            <OrbitControls
              enablePan={false}
              autoRotate
              enableZoom={false}
              maxPolarAngle={-Math.PI /3 }
              minPolarAngle={Math.PI /3}
            rotateSpeed={2}
             
            />
            <directionalLight intensity={5} position={[0, 50, 0]} />
            <ambientLight intensity={5} position={[0, 50, 0]} />
            <Model />
          </Suspense>
        </Canvas>
      </div>
    );
  } catch (error) {
    return <p>Sahifani qayta yuklang</p>
  }

};

export default ModelThree;
