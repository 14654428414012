import React, { useEffect, useState } from "react";
import { Footer } from "../../componet/userComponet/AllComponet";
import {
  Contact,
  ContactUs,
  HeaderNav,
} from "../../componet/userComponet/AllComponet";
import { SliderUstara } from "../../componet/userComponet/SliderUstara";
import YakkaSlider from "../../componet/userComponet/YakkaSlider";
import { DefaultlangAdd } from "../../redux/action/langAction";
import { useDispatch } from "react-redux";
import axios from "axios";
const Home = () => {
  const disp = useDispatch();
  disp(DefaultlangAdd());
  const [data, setData] = useState([]);
  useEffect(() => {
    const sliderData = async () => {
      let res = await axios.get(
        "https://backmeck.almazrazors.com/api/v1/products/"
      );
      if (res.status === 200) {
        setData(() => res.data);
      }
    };
    sliderData();
  }, []);
  return (
    <>
      <HeaderNav />
      <div id="max">
        <Contact />
        <SliderUstara data={data} />
        <YakkaSlider data={data} />
        <br />
        <br />
        <br />
        <ContactUs />
        <Footer />
      </div>
    </>
  );
};

export default Home;
