export const uz = {
        home_title:'XAVFSIZ USTARASI',
        nav_title:"BARCHA MAHSULOTLAR",
        lang:"uz",
        nav_contact:"BOG’LANISH",
        header_title:`PROFESSIONAL VA HAVASKOR\nBARBERLAR UCHUN`,
        contact_title:` O‘z ishini <br />
        mukammal darajada <br />
        bajarishni istagan <br />
        <span> har bir</span> <br />
        Barber uchun <br />
        <span> albatta kerak</span> <br />
        <span>bo‘ladigan uskuna</span> <br />`,
        btn:'MAHSULOT HAQIDA',
        contact_us_title:'MUKAMMAL USKUNA BILANGINA <br/>  MUKAMMAL ISH BAJARISH MUMKIN',
        adress:'Manzil <br /><span>Namangan sh. Yangi namangan tumani, g`ayratiy ko`cha 3-uy.</span>',
        contact_1:'Murojaat uchun',
        contact_2:'Elektron pochta',
        contact_3:'Instagram',   
        footer_1:'Barcha huquqlar himoyalangan',
        footer_2:'Sayt “ <a href="https://t.me/uitc_uz" target={"_blank"} >UNITED IT CLUBS</a> ” tomonidan ishlabchiqildi'  ,
        cursor:'Sichqonchani rasm ustiga olib boring' 

}