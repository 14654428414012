import React from 'react'

const Load = ({load}) => {
  return (
    <div className={load ? 'load activ' : 'load'}>
        <h1>Yuklanmoqda</h1>
    <img src="./img/load.svg" alt="" />        
    </div>
  )
}

export default Load