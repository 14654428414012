export const actionType = {
    galeryAdd:'galeryAdd',
    galeryEdit:'galeryEdit',
    galeryDelete:'galeryDelete',
    menu:'menu',
    // admin login
    user:'user',
    logout:'logout',
    // lang
    lang:'lang',
    langdef:'langdef',
    
}