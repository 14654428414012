import { en } from "../../lang/en/lang_en";
import { ru } from "../../lang/ru/lang_ru";
import { uz } from "../../lang/uz/lang_uz";
import { actionType } from "../actionType/actionTypes";

const initialValues = {
  langJson: {
    uz:uz,
    en:en,
    ru:ru

  },
  lang: {}
};

export const langReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case actionType.lang:
      localStorage.setItem('lang',payload)
      return {
        ...state,
        lang: state.langJson[localStorage.getItem('lang')],
      };
      case actionType.langdef:
        let lang = localStorage.getItem("lang");
            if (localStorage.getItem("lang")) {
              if (lang === "uz" || lang === "ru" || lang === "en") {
              } else {
                localStorage.setItem("lang", "en");
                lang = 'en'
              }
            } else {
              localStorage.setItem("lang", "en");
              lang = 'en'
            }
        return {
          ...state,
          lang: state.langJson[lang]  ,
        };
    default:
      return state;
  }
};
