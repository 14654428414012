export const ru = {
        home_title:'БЕЗОПАСНАЯ БРИТВА',
        nav_title:"ВСЕ ПРОДУКТЫ",
        lang:"ru",
        nav_contact:"КОНТАКТ",
        header_title:`ДЛЯ ПРОФЕССИОНАЛЬНОГО И\nЛЮБИТЕЛЬСКОГО ПАРИКМАХЕРА`,
        contact_title:'НЕОБХОДИМОЕ <br/> ОБОРУДОВАНИЕ ДЛЯ <br/> КАЖДОГО БАРБЕРА, <span>КОТОРЫЙ ХОЧЕТ</span> ВЫПОЛНЯТЬ СВОЮ <br/> РАБОТУ ИДЕАЛЬНО',
        btn:'О ПРОДУКТЕ',
        contact_us_title:'ИДЕАЛЬНАЯ РАБОТА МОЖЕТ ВЫПОЛНЯТЬСЯ <br/> ТОЛЬКО НА ИДЕАЛЬНОМ ОБОРУДОВАНИИ',
        adress:'АДРЕС <br /><span> Наманганское ш. Новый Наманганский район, 3 дом, улица Гаирати.</span',
        contact_1:'Для справки',
        contact_2:'Эл. адрес',
        contact_3:'Инстаграм',   
        footer_1:'все права защищены',
        footer_2:'Сайт разработан компанией “ <a href="https://t.me/uitc_uz" target={"_blank"} >UNITED IT CLUBS</a> ”. '  ,
        cursor:'Наведите мышку на картинку' 
}
