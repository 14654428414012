export const en = {
        home_title:'SAFETY RAZOR',
        nav_title:"ALL PRODUCTS",
        lang:"en",
        nav_contact:"CONTACT",
        header_title:`FOR PROFESSIONAL AND\nAMATEUR BARBER`,
        contact_title:  'His work perfectly  <br/> wanted to <br/> do  each  <span>For Barber</span>   of <br/> course it <span>is necessary</span> <br/> equipment to be',
        btn:'ABOUT THE PRODUCT',
        contact_us_title:'PERFECT WORK CAN ONLY BE <br/> DONE WITH PERFECT EQUIPMENT',
        adress:'ADDRESS  <br /><span> Namangan sh. New Namangan district, 3rd house, Ghairati street.</span>',
        contact_1:'For reference',
        contact_2:'Email',
        contact_3:'Instagram',   
        footer_1:'All rights reserved',
        footer_2:'The site was developed by “<a href="https://t.me/uitc_uz" target={"_blank"}  >UNITED IT CLUBS</a> ”.' ,
        cursor:'Move the mouse over the picture' 
}
