import React from "react";
import { Routes, Route } from "react-router-dom";
import "./ui/ui.css";
import 'aos/dist/aos.css'
import Erorr from "./pages/Erorr";
import Home from "./pages/User/Home";
import Aos from "aos";
import 'react-lazy-load-image-component/src/effects/blur.css';
Aos.init()
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Erorr />} />
    </Routes>
  );
};

export default App;
