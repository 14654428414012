import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import axios from "axios";
import ModuleLoad from "./Load";
import { LazyLoadImage } from "react-lazy-load-image-component";
export const SliderUstara = ({data}) => {
  const { lang } = useSelector((state) => state.langReducer);
  const [sliderCount, setSliderCounnt] = useState({
    activeSlide: null,
    activeSlide2: null,
    count: [],
  });
  const ref = useRef();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    beforeChange: (current, next) => {
      setSliderCounnt({ ...sliderCount, activeSlide: next });
    },
    afterChange: (current) =>
      setSliderCounnt({ ...sliderCount, activeSlide: current }),

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextClick = () => {
    ref.current.slickNext();
  };
  const PrevClick = () => {
    ref.current.slickPrev();
  };
  // const [data, setData] = useState([]);
  useEffect(() => {
    // const sliderData = async () => {
    //   let res = await axios.get(
    //     "https://backmeck.almazrazors.com/api/v1/aboutproducts/"
    //   );
    //   if (res.status === 200) {
    //     setData(()=>res?.data);
    //   }
    // };
    // sliderData();
  }, []);
  // img zomm
  const [zoom, setZoom] = useState({
    src: "./img/ustara.png",
    transformOrigin: "center",
    scale: `scale(1)`,
    data: {},
  });
  const [modal, setModal] = useState(false);
  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.className === "modal") {
        setModal(false);
      }
    });
    window.addEventListener("keydown", (e) => {
      if (e.code === "Escape") {
        setModal(false);
      }
    });
  }, []);

  const moveImg = (e) => {
    let clientX = e.clientX - e.target.offsetLeft,
      clientY = e.clientY - e.target.offsetTop;
    setZoom({
      ...zoom,
      transformOrigin: `${clientX - 180}px ${clientY - 100}px`,
      scale: `scale(3)`,
    });
  };

  const modalFun = (val) => {
    setModal(true);
    setZoom({
      ...zoom,
      data: val,
    });
  };

  const zoomEnd = () => {
    setZoom({
      ...zoom,
      transformOrigin: `center`,
      scale: `scale(1)`,
    });
  };
  const [all, setAll] = useState(false);
  return (
    <div className="ustaraCorusel" id="maxsulot">
      <div className={modal ? "modal" : "modal activ"}>
        <div className="modal_grid">
          <div
            className="modal_body"
            onMouseMove={moveImg}
            onMouseLeave={zoomEnd}
            onTouchMove={moveImg}
            data-key={lang.cursor}
          >
            <img
              src={zoom.data.image}
              alt="ustara"
              style={{
                transformOrigin: zoom.transformOrigin,
                transform: zoom.scale,
              }}
            />
          </div>
          <div className="modal_box">
            <h1> {modal ? zoom.data.translations[lang.lang].title : ""}</h1>
            <p>{modal ? zoom.data.translations[lang.lang].description : ""}</p>
          </div>
          <button className="close" onClick={() => setModal(false)}>
            <span> +</span>
          </button>
        </div>
      </div>

      {data.length > 0 ? (
        <>
          <div className="sliderHeader pad">
            <h1 data-aos="fade-right"
                data-aos-duration="1500">{lang.nav_title}</h1>
            <div className="sliderScroll" data-aos="fade-left"
                data-aos-duration="1500">
              <span className="sliderBox activ start" data-key="01"></span>
              <span className="sliderBox"></span>
              <span className="sliderBox"></span>
              <span className="sliderBox"></span>
              <span className="sliderBox"></span>
              <span
                className="sliderBox end"
                data-key={data.length < 10 ? `0${data.length}` : data.length}
              ></span>
            </div>
          </div>
          <br />
          <br />
          <img
            src="./img/BlobShape__1.svg"
            className="coruselDetil"
            alt="suzuchi"
          />
          <Slider ref={ref} {...settings}  
                   >
            {data.map((val, i) => {
              const { title, description } = val.translations[lang.lang] || "";
              return (
                <div key={i}  data-aos="fade-up"
                data-aos-duration="1500">
                  <div>
                    <div className="cardUstara" >
                    <LazyLoadImage
                      alt={val.image}
                      effect="blur"
                      src={val.image}
                    />
                      <div className="ustaraText">
                        <div className="ustarBox">
                          <h1> {title}</h1>
                          <p>{description.slice(0, 200)}...</p>
                        </div>
                      </div>
                      <button className="zoom_" onClick={() => modalFun(val)}>
                        <img src="./img/zoom.svg" alt="zoom" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          <button className="btn_ maxsulot_btn" onClick={() => setAll(true)}>
            {lang.btn}
          </button>
          <button
            className="sliderItems sliderItems sliderPrev"
            onClick={PrevClick}
          >
            <img src="./img/left.svg" alt="left" />
          </button>
          <button className="slidernex sliderItems" onClick={nextClick}>
            <img src="./img/left.svg" alt="left" />
          </button>
        </>
      ) : (
        <ModuleLoad load={data.length > 0 ? false : true} />
      )}
      {all ? (
        <div className="grid__">
          {data.map((val, i) => {
            const { title, description } = val.translations[lang.lang] || "";
            return (
              <div key={i}>
                <div     data-aos="fade-up"
                    data-aos-duration="1500">
                  <div
                    className="cardUstara"
                
                  >
                    <LazyLoadImage
                      alt={val.image}
                      effect="blur"
                      src={val.image}
                    />
                    <div className="ustaraText">
                      <div className="ustarBox">
                        <h1> {title}</h1>
                        <p>{description.slice(0, 200)}...</p>
                      </div>
                    </div>
                    <button className="zoom_" onClick={() => modalFun(val)}>
                      <img src="./img/zoom.svg" alt="zoom" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
