import ModelThree from "./ModelThree";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { langAdd } from "../../redux/action/langAction";
import { useRef } from "react";
import { useEffect } from "react";
export const HeaderNav = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.langReducer);
  const [langBolen, setLangBolen] = useState(false);
  const langFun = () => {
    setLangBolen(!langBolen);
  };
  const langEdit = (lang) => {
    dispatch(langAdd(lang));
    langFun();
  };
  return (
    <>
      <div className="main-header ">
        <div className="pad " id="max">
          <nav className="userNav">
            <div className="maxsulot">
              <a href="#maxsulot">
                <span>
                  <img
                    className="scale"
                    src="./img/all_product.svg"
                    alt="all_product"
                  />
                </span>
                <span className="text_nav">{lang.nav_title}</span>
              </a>
            </div>
            <div className="logo">
              <a href="#logo">
                <img src="./logo/logo.svg" alt="logo" />
              </a>
            </div>
            <ul className="detil">
              <li>
                <button onClick={langFun}>
                  <span>
                    <img className="scale" src="./img/lang.svg" alt="lang" />
                  </span>
                  <span>
                    <span
                      className="text_nav lang_btn"
                      style={{ background: "none" }}
                    >
                      {lang.lang}
                    </span>
                  </span>
                </button>
                <div className={langBolen ? "list_lang activ" : "list_lang"}>
                  <button
                    className={lang.lang === "uz" ? "activ" : ""}
                    onClick={() => langEdit("uz")}
                  >
                    uz
                  </button>
                  <button
                    className={lang.lang === "ru" ? "activ" : ""}
                    onClick={() => langEdit("ru")}
                  >
                    ru
                  </button>
                  <button
                    className={lang.lang === "en" ? "activ" : ""}
                    onClick={() => langEdit("en")}
                  >
                    en
                  </button>
                </div>
              </li>
              <li>
                <a href="tel:+998901540045">
                  <span>
                    <img
                      className="scale"
                      src="./img/contact_link.svg"
                      alt="lang"
                    />
                  </span>
                  <span className="text_nav">{lang.nav_contact}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div id="">
        <div className="headerNav pad" id="top">
          <header className="headerUser">
            <h1 data-aos="fade-up" data-aos-duration="1500">
              {lang.header_title}
            </h1>
            <div className="animate_header">
              <div className="animate__">
                <img src="./img/doira_1.svg" alt="" />
              </div>
              <div className="animate__">
                <img src="./img/doira_.svg" alt="" />
              </div>
              <div className="animate__">
                <ModelThree />
              </div>
              <div className="animate_text">
                <h2>ai-ma7</h2>
                <p>{lang.home_title}</p>
                <a href="#conatct" className="imgs_bottom">
                  <img src="./img/bottom.svg" alt="img" />
                </a>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};
export const Contact = () => {
  const [modal, setModal] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (ref.current !== undefined) {
      if (!modal) {
        ref.current.pause();
      }
    }
    window.addEventListener("click", (e) => {
      if (e.target.className === "modal") {
        setModal(false);
      }
    });
    window.addEventListener("keydown", (e) => {
      if (e.code === "Escape") {
        setModal(false);
      }
    });
  }, [ref, modal]);
  const { lang } = useSelector((state) => state.langReducer);
  return (
    <div className="contact  " id="conatct">
      <div className={modal ? "modal" : "modal activ"}>
        <div className="modal_video">
          <video ref={ref} src="./video/almaz.mp4" controls></video>
          <button onClick={() => setModal(false)} className="close">
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="grid ">
        <div
          className="contactBox"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <h1
            dangerouslySetInnerHTML={{ __html: `${lang.contact_title}` }}
          ></h1>
          <div className="ends">
            <button className="btn_ play" onClick={() => setModal(!modal)}>
              <span>
                {/* Video */}
                <svg
                  className="animate-shadow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div
          className="contactBox"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <img src="./img/contact.png " alt="img" />
          <div className="imgContact"></div>
        </div>
      </div>
      <img src="./img/BlobShape.svg" alt="fon-circle" className="circle" />
    </div>
  );
};

export const ContactUs = () => {
  const { lang } = useSelector((state) => state.langReducer);
  return (
    <div className="contactUs" id="contactUs">
      <div className="shape2">
        <img src="./img/shape1.svg" alt="shape1" />
        <img src="./img/shape2.svg" alt="shape2" />
      </div>
      <div className="imgContact"></div>
      <div className="contactusItem">
        <h1
          data-aos="fade-up"
          data-aos-duration="1500"
          dangerouslySetInnerHTML={{ __html: `${lang.contact_us_title}` }}
        ></h1>
        <div className="pad">
          <div className="grid3" data-aos="fade-up" data-aos-duration="1500">
            <a href="tel:+998901540045" className="grid_item contact_net">
              <img src="./img/phone.svg" alt="contact" />
              <p>{lang.contact_1}</p>
              <p>+998 90 154 00 45</p>
            </a>
            <div className="grid_item contact_net">
              <img src="./img/email.svg" alt="contact" />
              <p>{lang.contact_2}</p>
              <p>almazrazors@gmail.com</p>
            </div>
            <a
              href="http://www.instagram.com/almazrazors"
              className="grid_item contact_net"
            >
              <img src="./img/ins.svg" alt="contact" />
              <p>{lang.contact_3}</p>
              <p>@almazrazors</p>
            </a>
          </div>
        </div>
        <p className="manzil" data-aos="fade-up" data-aos-duration="1500">
          <a
            href="https://maps.google.com/maps?q=41.025341,71.608722&ll=41.025341,71.608722&z=16"
            dangerouslySetInnerHTML={{ __html: `${lang.adress}` }}
          ></a>
        </p>
      </div>
    </div>
  );
};

export const Footer = () => {
  const { lang } = useSelector((state) => state.langReducer);

  const top = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer>
      <div className="pad">
        <div className="footer">
          <ul className="footerBox">
            <li>
              <a href="tel:+998901540045" className="footer-link">
                +998 90 154 00 45
              </a>
            </li>
            <li>
              <a href="#almaz" className="footer-link">
                almazrazors@gmail.com
              </a>
            </li>
          </ul>
          <div className="logo-footer">
            <a href="#logo">
              <img src="./logo/logo.svg" alt="logo" />
            </a>
          </div>
          <ul className="footerBox">
            <li>
              <a href="#maxsulot" className="footer-link">
                {lang.nav_title}
              </a>
            </li>
            <li>
              <button onClick={top} className="footer-link animate-shadow">
                <img src="./img/bottom.svg" alt="top" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="pad">
        <div className="flexFooter ">
          <p> {lang.footer_1}</p>
          <p dangerouslySetInnerHTML={{ __html: `${lang.footer_2}` }}></p>
        </div>
      </div>
    </footer>
  );
};
