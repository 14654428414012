import { actionType } from "../actionType/actionTypes";

const initialValues = {
    user: JSON.parse(localStorage.getItem('profile')) || {},
};

export const profileReducer = (state = initialValues, { type, payload }) => {
    switch (type) {
        case actionType.user:
            localStorage.setItem('profile', JSON.stringify(payload))
            return {
                ...state,
                user: JSON.parse(localStorage.getItem('profile')) || {},
            };
            case actionType.logout:
                localStorage.removeItem('profile')
                return {
                    ...state,
                    user: JSON.parse(localStorage.getItem('profile')) || {},
                }
        default:
            return state;
    }
};
