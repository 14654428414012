import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { useSelector } from "react-redux";
import ModuleLoad from "./Load";
const YakkaSlider = ({data}) => {
  const { lang } = useSelector((state) => state.langReducer);
  const [sliderCount, setSliderCounnt] = useState({
    activeSlide: 0,
    activeSlide2: 0,
    count: [],
  });

  const ref = useRef();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    row: 0,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease-in-out",
    beforeChange: (current, next) => {
      setSliderCounnt({ ...sliderCount, activeSlide: next });
    },
    afterChange: (current, nex) =>
      setSliderCounnt({ ...sliderCount, activeSlide: current }),
    appendDots: (dots) => (
      <div>
        <p>Keyingi rasmlar</p>
        <div className="flexCoruss">
          <button
            className="sliderItems sliderItems sliderPrev"
            onClick={PrevClick}
          >
            <img src="./img/left.svg" alt="left" />
          </button>
          <ul style={{ margin: "0px" }}> {dots} </ul>
          <button className="slidernex sliderItems" onClick={nextClick}>
            <img src="./img/left.svg" alt="left" />
          </button>
        </div>
      </div>
    ),
    customPaging: (i) => (
      <div className="sliderBox">
        <span className="sliderBox"></span>
      </div>
    ),
  };

  const nextClick = () => {
    ref.current.slickNext();
  };
  const PrevClick = () => {
    ref.current.slickPrev();
  };
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   const sliderData = async () => {
  //     let res = await axios.get("https://backmeck.almazrazors.com/api/v1/products/");
  //     if (res.status === 200) {
  //       setData(res.data);
  //     }
  //   };
  //   sliderData();
  // }, []);

  return (
    <div id="maxsulotHaqida">
      {data.length > 0 ? (
        <div className="pad  yakkaCorus">
          <img
            src="./img/BlobShape_view.svg"
            className="yakkaDoira"
            alt="animatsiya"
          />
          <div className="firstCorus" data-aos="fade-right"
                data-aos-duration="1500">
            <img
              src={
                data.length > 0
                  ? data[sliderCount.activeSlide].image
                  : "./img/BlobShape_view.svg"
              }
              alt="karobka"
            />
          </div>

          <div className="firstCorus" data-aos="fade-left"
                data-aos-duration="1500">
            <Slider
              ref={ref}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              {...settings}
              // asNavFor={this.state.nav1}
            >
              {data.map((val, i) => {
                const { title, description } =
                  val.translations[lang.lang] || "";
                return (
                  <div key={i} className='slidersTexts'>
                    <div>
                      <h1 data-aos="fade-up"
                data-aos-duration="1500">{title}</h1>
                      <p data-aos="fade-up"
                data-aos-duration="1500" data-aos-delay='500'>{description}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      ) : (
        <ModuleLoad load={data.length > 0 ? false : true} />
      )}
    </div>
  );
};

export default YakkaSlider;
