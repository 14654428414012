import { actionType } from "../actionType/actionTypes"

export const langAdd = (lang)  =>{
    return{
        type:actionType.lang    ,
        payload:lang
    }
}

export const DefaultlangAdd = ()  =>{
    return{
        type:actionType.langdef ,
    }
}